import type { CustomElement } from '@integrabeauty/custom-elements';
import { startTimer, timeLeft } from '../../lib/timer.js';
import html from './index.html';
import styles from './index.scss';

class HeroBanner extends HTMLElement implements CustomElement {
  readonly dataset!: {
    /**
     * Badge background color override
     */
    badgeBackgroundColor: string;

    /**
     * Show badge on top
     */
    badgeShow: 'false' | 'true';

    /**
     * Text on the badge
     */
    badgeText: string;

    /**
     * Badge text color override
     */
    badgeTextColor: string;

    /**
     * Stop time as unix epoch time.
     *
     * @see https://www.epochconverter.com
     */
    clockEndTime: string;

    /**
     * Should the clock be displayed on the banner
     */
    clockShow: 'false' | 'true';

    /**
     * When a background photo is dark we have to display
     * the content elements using white instead of black
     * to keep the contrast high.
     */
    contentInvertColors: 'false' | 'true';

    /**
     * Where to put the content on desktop
     */
    contentPosition: 'left' | 'middle' | 'right';

    /**
     * Text on the CTA button
     */
    ctaText: string;

    /**
     * CTA link
     */
    ctaUrl: string;

    /**
     * Show disclaimer in the bottom section
     */
    disclaimerShow: 'false' | 'true';

    /**
     * Disclaimer text
     */
    disclaimerText: string;

    /**
     * Should the text disclaimer be displayed bold (true) or normal (false)
     */
    headlineBold: 'false' | 'true';

    /**
     * Color of the text headline (doesn't apply to SVGs)
     */
    headlineColor: string;

    /**
     * URL to a desktop version of headline SVG
     */
    headlineSvgDesktop: string;

    /**
     * URL to a mobile version of headline SVG
     */
    headlineSvgMobile: string;

    /**
     * Headline text
     *
     * Should always be set, even if SVGs are used
     */
    headlineText: string;

    /**
     * Bottom margin
     */
    marginBottom: 'large' | 'medium' | 'none' | 'small';

    /**
     * Component type - defines the aspect ratio and behavior
     *
     * Small doesn't display badges, clocks, and disclaimers
     */
    size: 'big' | 'medium' | 'small';

    /**
     * Text of the sub headline
     */
    subHeadlineText: string;
  };

  public shadowRoot!: ShadowRoot;
  private endTimestamp: number | null = null;

  private mobileVideo: string | undefined;
  private desktopVideo: string | undefined;

  private intersectionObserver: IntersectionObserver | undefined;

  private onTimerTickBound = this.onTimerTick.bind(this);
  private onIntersectionEntryBound = this.onIntersectionEntry.bind(this);

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public attributeChangedCallback(_name: string, oldValue: string, newValue: string) {
    if (this.isConnected && oldValue !== newValue) {
      this.render();
    }
  }

  public connectedCallback() {
    if (this.dataset.clockEndTime) {
      this.endTimestamp = parseInt(this.dataset.clockEndTime, 10) * 1000;
    }

    if (this.dataset.clockShow === 'true' && this.endTimestamp > Date.now()) {
      startTimer();
      addEventListener('timer-tick', this.onTimerTickBound);
    }

    const desktopSource = this.querySelector<HTMLSourceElement>('source[data-type="desktop"]');
    this.desktopVideo = desktopSource?.dataset.src;
    const mobileSource = this.querySelector<HTMLSourceElement>('source[data-type="mobile"]');
    this.mobileVideo = mobileSource?.dataset.src;

    // set an intersection observer, so we can disable the video when not in the viewport
    if (this.desktopVideo && this.mobileVideo && 'IntersectionObserver' in window) {
      this.intersectionObserver = new IntersectionObserver(
        this.onIntersectionEntryBound,
        { rootMargin: '100px' }
      );
      const video = this.querySelector('video');
      this.intersectionObserver.observe(video);
    }

    this.render();
  }

  public disconnectedCallback() {
    removeEventListener('timer-tick', this.onTimerTickBound);
    this.intersectionObserver?.disconnect();
  }

  private onTimerTick(event: WindowEventMap['timer-tick']) {
    this.renderClock(event.detail.timestamp);
  }

  private onIntersectionEntry(entries: IntersectionObserverEntry[]) {
    if (entries?.length === 1) {
      const entry = entries[0];
      const video = this.querySelector('video');
      if (entry.isIntersecting) {
        const src = window.innerWidth < 1024 ? this.mobileVideo : this.desktopVideo;
        if (video.src !== src) {
          video.src = src;
        }
      } else {
        video.src = '';
      }
    }
  }

  private render() {
    const content = this.shadowRoot.querySelector('.content');
    content.classList.add(this.dataset.size);
    content.classList.add(this.dataset.contentPosition);

    const badgeSpan = this.shadowRoot.querySelector('.badge span');
    badgeSpan.textContent = this.dataset.badgeText;

    const badge = this.shadowRoot.querySelector('.badge');
    if (this.dataset.badgeShow === 'true') {
      badge.classList.remove('hidden');
    }

    const cta = this.shadowRoot.querySelector<HTMLAnchorElement>('.cta a');
    cta.textContent = this.dataset.ctaText;
    cta.href = this.dataset.ctaUrl;

    const isVideoBanner = this.desktopVideo && this.mobileVideo;

    const disclaimerSpan = this.shadowRoot.querySelector('.disclaimer span');
    disclaimerSpan.textContent = this.dataset.disclaimerText;

    const disclaimer = this.shadowRoot.querySelector('.disclaimer');
    disclaimer.classList.add(this.dataset.size);
    disclaimer.classList.add(this.dataset.contentPosition);
    if (this.dataset.disclaimerShow === 'true' && !isVideoBanner) {
      disclaimer.classList.remove('hidden');
    }

    const dynamicStyles: string[] = [];
    if (this.dataset.headlineColor) {
      dynamicStyles.push([
        '.headline h2 {color:',
        this.dataset.headlineColor,
        ' !important;}'
      ].join(''));
    }

    if (this.dataset.badgeBackgroundColor) {
      dynamicStyles.push([
        '.badge {background-color:',
        this.dataset.badgeBackgroundColor,
        ';}'
      ].join(''));
    }

    if (this.dataset.badgeTextColor) {
      dynamicStyles.push([
        '.badge {color:',
        this.dataset.badgeTextColor,
        ';}'
      ].join(''));
    }

    let margin = '0px';
    if (this.dataset.marginBottom === 'small') {
      margin = '8px';
    } else if (this.dataset.marginBottom === 'medium') {
      margin = '16px';
    } else if (this.dataset.marginBottom === 'large') {
      margin = '32px;';
    }

    dynamicStyles.push([
      ':host {margin-bottom:',
      margin,
      ';}'
    ].join(''));

    const dynamicStylesheet = this.shadowRoot.getElementById('dynamic-stylesheet');
    dynamicStylesheet.textContent = dynamicStyles.join('\n');

    const headline = this.shadowRoot.querySelector('.headline');
    const headlineMobileImg = headline.querySelector<HTMLImageElement>('img.mobile');
    const headlineDesktopImg = headline.querySelector<HTMLImageElement>('img.desktop');
    const headlineText = headline.querySelector('span');

    if (this.dataset.headlineSvgMobile) {
      headlineMobileImg.src = this.dataset.headlineSvgMobile;
      headlineMobileImg.alt = this.dataset.headlineText;
      headlineMobileImg.classList.remove('hidden');
      // if a separate desktop SVG is not provided we reuse the mobile one
      headlineDesktopImg.src = this.dataset.headlineSvgDesktop || this.dataset.headlineSvgMobile;
      headlineDesktopImg.alt = this.dataset.headlineText;
      headlineDesktopImg.classList.remove('hidden');
    } else {
      headlineText.textContent = this.dataset.headlineText;
      headlineText.classList.remove('hidden');
    }

    if (this.dataset.headlineBold === 'true') {
      headlineText.classList.add('bold');
    }

    const subHeadline = this.shadowRoot.querySelector('.sub-headline span');
    if (!isVideoBanner) {
      subHeadline.textContent = this.dataset.subHeadlineText;
    }

    const timer = this.shadowRoot.querySelector<HTMLElement>('.timer');
    if (this.dataset.clockShow === 'true' &&
      this.endTimestamp > Date.now() &&
      !isVideoBanner) {
      timer.classList.remove('hidden');
    }
  }

  private renderClock(timestamp: number) {
    const timer = this.shadowRoot.querySelector<HTMLElement>('.timer');
    if (this.dataset.clockShow !== 'true' || this.endTimestamp < Date.now()) {
      timer.classList.add('hidden');
      return;
    }

    const remaining = timeLeft(timestamp, this.endTimestamp);

    this.setClockSegmentValue('.days', remaining.days);
    this.setClockSegmentValue('.hours', remaining.hours);
    this.setClockSegmentValue('.minutes', remaining.minutes);
    this.setClockSegmentValue('.seconds', remaining.seconds);

    timer?.setAttribute('aria-label', remaining.text);
  }

  private setClockSegmentValue(selector: string, value: number) {
    const stringValue = value < 10 ? '0' + value : value.toString();
    const element = this.shadowRoot.querySelector(selector);
    if (element && element.textContent !== stringValue) {
      element.textContent = stringValue;
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'hero-banner': HeroBanner;
  }
}

if (!customElements.get('hero-banner')) {
  customElements.define('hero-banner', HeroBanner);
}
