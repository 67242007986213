/**
 * Check for document.body. Fire a document-body-ready event when found.
 *
 * To minimize the number of checks, wait to begin observing until after DOMContentLoaded.
 *
 * Only the first two parameters should be specified.
 *
 * The first check is in the same tick. Listeners for this event should already be registered.
 *
 * The reason this does a check in the same tick is because this tries to preserve the behavior of
 * most normal code that would immediately access document.body after DOMContentLoaded. We do not
 * want to introduce an extra delay in the more frequent case.
 *
 * This is useful when there is a need to reliably wait for document.body to exist, such as when
 * encountering errors where document.body is not defined even after DOMContentLoaded.
 *
 * @param delay how long to wait between each attempt after the first
 * @param maxAttempts the maximum number of times to check
 * @param attempts internal, do not specify this, this tracks number of attempts
 */
export function observeDocumentBodyReady(delay = 50, maxAttempts = 50, attempts = 0) {
  if (document.body || attempts > maxAttempts) {
    const event = new CustomEvent('document-body-ready', { detail: { attempts } });
    dispatchEvent(event);
  } else {
    setTimeout(observeDocumentBodyReady, delay, delay, maxAttempts, attempts + 1);
  }
}

type DocumentBodyReadyEvent = CustomEvent<{
  /**
   * The number of checks that were made for document.body.
   */
  attempts: number;
}>;

declare global {
  interface WindowEventMap {
    'document-body-ready': DocumentBodyReadyEvent;
  }
}
