// We have now more than one element that displays a countdown clock on the page. When the elements
// use separate `setInterval()` calls to display their time the effect of them not being
// synchronized can be seen even if the interval is low (50-100ms).
//
// This module provides a very simple synchronization method and some shared utilities for timestamp
// calculations.
//
// Clock synchronization is based on a single `setInterval()` call. This starts a timer which emits
// `timer-tick` events. Each page element can listen to the events to update the displayed time at
// the very same moment other elements do.
//
// The timer has to be initialized with the `startTimer()` function. There is no possibility to stop
// the timer, as we would have to implement a reference counter which would be prone to problems,
// i.e. a bug in a simple component could stop all timers on the page. Instead of stopping the timer
// the components should stop listening to the events.

let intervalId: ReturnType<typeof setInterval> | null = null;

/**
 * Calculate the remaining time between currentTimestamp and endTimestamp and express it as an
 * object with number of days, hours, minutes, seconds as integers and a human readable `text`
 */
export function timeLeft(currentTimestamp: number, endTimestamp: number) {
  if (currentTimestamp > endTimestamp) {
    return null;
  }

  const secondsLeft = Math.round((endTimestamp - currentTimestamp) / 1000);
  const seconds = secondsLeft % 60;

  const minutesLeft = (secondsLeft - seconds) / 60;
  const minutes = minutesLeft % 60;

  const hoursLeft = (minutesLeft - minutes) / 60;
  const hours = hoursLeft % 24;

  const days = (hoursLeft - hours) / 24;

  const text = [
    days.toString(),
    days === 1 ? 'day' : 'days',
    hours.toString(),
    hours === 1 ? 'hour' : 'hours',
    minutes.toString(),
    minutes === 1 ? 'minute' : 'minutes',
    seconds.toString(),
    seconds === 1 ? 'second' : 'seconds'
  ].join(' ');

  return {
    text, days, hours, minutes, seconds
  };
}

/**
 * Start emitting `timer-tick` events
 *
 * This function may be called multiple times. It will only start a single timer.
 */
export function startTimer() {
  if (intervalId) {
    return;
  }

  // dispatch the first event immediately
  setTimeout(tick, 0);

  intervalId = setInterval(tick, 1000);
}

function tick() {
  type Detail = TimerTickEvent['detail'];
  const event = new CustomEvent<Detail>('timer-tick', {
    detail: { timestamp: Date.now() }
  });
  dispatchEvent(event);
}

type TimerTickEvent = CustomEvent<{
  timestamp: number;
}>;

declare global {
  interface WindowEventMap {
    'timer-tick': TimerTickEvent;
  }
}
